import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../assets/css/Login.css"
import { toFollowEst, toggleLoginScreen } from "../actions/AccountAction"
import Icon from "@identitybuilding/idb-react-iconlib";
import { OsnInputDate, OsnInputText, OsnSelect } from "@identitybuilding/idb-react-ui-elements";
import axios from 'axios';

// function useForceUpdate() {
//     const [value, setValue] = useState(0); // integer state
//     return () => setValue(value => value + 1); // update state to force render
//     // An function that increment 👆🏻 the previous state like here 
//     // is better than directly setting `value + 1`
// }


const Login = (props) => {
    // const forceUpdate = useForceUpdate();

    const dispatch = useDispatch()
    const { translate, lang } = useSelector(state => state.general);
    const { toFollowEstablishment, accountData } = useSelector(state => state.account);
    const [loginType, setLoginType] = useState('login');
    const [error, setError] = useState('');
    const [forgotPassword, setForgotPassword] = useState(false);
    const [forgotEmail, setForgotEmail] = useState('');

    const [zoomIn, setZoomIn] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);
    const [viewPassword1, setViewPassword1] = useState(false);
    const [viewPassword2, setViewPassword2] = useState(false);
    const [viewPassword3, setViewPassword3] = useState(false);
    const [viewPassword4, setViewPassword4] = useState(false);
    const [viewPassword5, setViewPassword5] = useState(false);

    const [userType, setUserType] = useState('consumer');
    const [accountInformation, setAccountInformation] = useState(false);
    const [preferred_language, setPreferred_language] = useState(lang === 'nl' ? { id: 'nl', name: 'Nederlands' } : { id: 'fr', name: 'Français' });

    // login states
    const [LoginEmailUsername, setLoginEmailUsername] = useState('');
    const [LoginPassword, setLoginPassword] = useState('');

    // register states
    // consumer
    const [registerEmail, setRegisterEmail] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [registerRepeatPassword, setRegisterRepeatPassword] = useState('');
    // business
    const [registerEstablishmentNumber, setRegisterEstablishmentNumber] = useState('');
    const [registerEnterpriseNumber, setRegisterEnterpriseNumber] = useState('');
    const [registerOtp, setRegisterOtp] = useState('');

    // extra info register
    const [registerUsername, setRegisterUsername] = useState('');
    const [registerFirstName, setRegisterFirstName] = useState('');
    const [registerLastName, setRegisterLastName] = useState('');
    const [registerDateOfBirth, setRegisterDateOfBirth] = useState('');
    const [registerTelephone, setRegisterTelephone] = useState('');


    const resetValues = () => {
        setRegisterEmail('')
        setRegisterPassword('')
        setRegisterRepeatPassword('')
        setRegisterEstablishmentNumber('')
        setRegisterEnterpriseNumber('')
        setRegisterOtp('')
    }

    const isUpper = (str) => {
        return /[A-Z]/.test(str);
    }
    const hasNumber = (str) => {
        return /[0-9]/.test(str);
    }

    const registerHandler = () => {
        if (userType === 'consumer') {
            if (registerEmail === '') { props.createNotification("warning", translate('please_enter_an_email')); setError('email') }
            else if (!/\S+@\S+\.\S+/.test(registerEmail)) { props.createNotification("warning", translate('not_a_valid_email')); setError('email') }
            else if (registerPassword === "") { props.createNotification("warning", translate('please_enter_a_password')); setError('password') }
            else if (registerPassword.length < 8) { props.createNotification("warning", translate('password_8_characters')); setError('password') }
            else if (!isUpper(registerPassword)) { props.createNotification("warning", translate('password_1_capital')); setError('password') }
            else if (!hasNumber(registerPassword)) { props.createNotification("warning", translate('password_1_number')); setError('password') }
            else if (registerRepeatPassword === "") { props.createNotification("warning", translate('enter_password_repeat')); setError('repeat_password') }
            else if (registerPassword !== registerRepeatPassword) { props.createNotification("warning", translate('passwords_do_not_match')); setError('repeat_password') }
            else {
                axios.post(`https://management.100procentlokaal.be/core/api/catalogus/check/email/`, {
                    email: registerEmail,
                }).then((res) => {
                    if (res.data.status === 'Ok') { setAccountInformation(true) }
                    else { props.createNotification("warning", translate('email_already_in_use')); setError('email') }
                })
            }
        }
        else {
            if (registerEmail === '') { props.createNotification("warning", translate('please_enter_an_email')); setError('email') }
            else if (!/\S+@\S+\.\S+/.test(registerEmail)) { props.createNotification("warning", translate('not_a_valid_email')); setError('email') }
            else if (registerPassword === "") { props.createNotification("warning", translate('please_enter_a_password')); setError('password') }
            else if (registerPassword.length < 8) { props.createNotification("warning", translate('password_8_characters')); setError('password') }
            else if (!isUpper(registerPassword)) { props.createNotification("warning", translate('password_1_capital')); setError('password') }
            else if (!hasNumber(registerPassword)) { props.createNotification("warning", translate('password_1_number')); setError('password') }
            else if (registerRepeatPassword === "") { props.createNotification("warning", translate('enter_password_repeat')); setError('repeat_password') }
            else if (registerPassword !== registerRepeatPassword) { props.createNotification("warning", translate('passwords_do_not_match')); setError('repeat_password') }
            else if (registerEnterpriseNumber === "") { props.createNotification("warning", translate('please_enter_enterprise_number')); setError('enterprise') }
            else if (registerEstablishmentNumber === "") { props.createNotification("warning", translate('please_enter_establishment_number')); setError('establishment') }
            else if (registerOtp === "") { props.createNotification("warning", translate('please_enter_otp')); setError('otp') }
            else {
                // controle op OTP en establishments + enterprise
                axios.post(`https://management.100procentlokaal.be/core/api/catalogus/check/email/`, {
                    email: registerEmail,
                }).then((res) => {
                    if (res.data.status === 'Ok') { setAccountInformation(true) }
                    else { props.createNotification("warning", translate('email_already_in_use')); setError('email') }

                })
            }
        }
    }

    const extraRegisterHandler = () => {
        if (registerUsername === '') { props.createNotification("warning", translate('please_enter_username')); setError('username') }
        else if (registerFirstName === '') { props.createNotification("warning", translate('please_enter_first_name')); setError('prename') }
        else if (registerLastName === '') { props.createNotification("warning", translate('please_enter_last_name')); setError('name') }
        else {
            axios.post(`https://management.100procentlokaal.be/core/api/catalogus/check/username/`, {
                username: registerUsername,
            }).then((res) => {
                if (res.data.status === 'Ok') {
                    axios.post(`https://management.100procentlokaal.be/core/api/catalogus/confirm/?site=promonetwerk"`, {
                        registerEmail: registerEmail,
                        registerPassword: registerPassword,
                        registerRepeatPassword: registerRepeatPassword,
                        registerEstablishmentNumber: registerEstablishmentNumber,
                        registerEnterpriseNumber: registerEnterpriseNumber,
                        registerOtp: registerOtp,
                        registerUsername: registerUsername,
                        registerFirstName: registerFirstName,
                        registerLastName: registerLastName,
                        registerDateOfBirth: registerDateOfBirth,
                        registerTelephone: registerTelephone,
                        preferred_language: preferred_language,
                        userType: userType
                    }).then(() => {
                        props.createNotification("info", translate('please_confirm_account'))
                        setTimeout(() => {
                            dispatch(toggleLoginScreen(false))
                            dispatch(toFollowEst(""))
                        }, 2000);
                    })
                }
                else { props.createNotification("warning", translate('username_already_in_use')); setError('username') }
            })


        }
    }


    const followHandler = () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("promotoken")}`,
        };
        axios.post(`https://management.100procentlokaal.be/core/api/catalogus/follow_establishment/${toFollowEstablishment}/?lang=${lang}`, {
            headers: headers,
            token: localStorage.getItem("promotoken"),
            following: true
        }).then(() => {

        }).catch((err) => {
            if (err.response.data.Status === 'Duplicate group') {
                // dispatch(toggleOpenFollowingConflict(err.response.data.establishment))
            }
        })

    }



    const loginHandler = () => {
        axios.post(`https://management.100procentlokaal.be/core/api/catalogus/login/`, {
            emailUsername: LoginEmailUsername,
            password: LoginPassword,
        })
            .then((res) => {
                localStorage.setItem("promotoken", res.data.token);
                followHandler()
                setTimeout(() => {
                    window.location.reload(true);
                }, 500);
            })
            .catch((error) => {
                console.error(error)
                setError('login')
            })
    }


    const forgotHandler = () => {
        if (forgotEmail === "") { props.createNotification("warning", translate('please_enter_an_email')); setError('repeat_password') }
        else {
            axios.post(`https://management.100procentlokaal.be/core/api/catalogus/forgot/?site=promonetwerk`, {
                emailUsername: forgotEmail,
            })
                .then((res) => {
                    props.createNotification("info", translate('check_mail_password_reset'))
                })
                .catch((error) => {
                    props.createNotification("warning", "Opgegeven email bestaat niet");
                })
        }
    }

    // when pressing escape, this will close the login screen
    const keyDownHandler = (e) => {
        if (e.keyCode === 27) {
            dispatch(toggleLoginScreen(false))
            dispatch(toFollowEst(""))

        }
        // if (e.keyCode === 13) { loginHandler() }
    }

    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler);
    })


    return (
        <div id="login_wrapper">
            <div className="login_content">
                <Icon className={`close_icon ${zoomIn ? 'zoom' : ''}`} name="Close" onClick={() => { dispatch(toggleLoginScreen(false)); dispatch(toFollowEst("")) }} />
                <figure>
                    <img src={require("../assets/imgs/logos/pn/promonetwerk-01.svg").default} alt="logo promonetwork" />
                </figure>
                {/* {accountInformation ?
                    <h2>bedankt om <span style={{ "color": "var(--pn)" }}>&nbsp;jouw account&nbsp;</span>te bevestigen!</h2>
                    : */}
                {lang === 'nl' ?
                    <h2>Welkom op het <span style={{ "color": "var(--pn)" }}>&nbsp;Promonetwerk</span></h2>
                    :
                    <h2>Bienvenue sur le Réseau<span style={{ "color": "var(--pn)" }}>promo</span></h2>
                }
                {/* } */}
                {
                    accountInformation ?
                        <div className="login_extra_info">
                            {lang === 'nl' ?
                                <h2>Vul hieronder nog wat <span style={{ "color": "var(--pn)" }}>&nbsp;gegevens&nbsp;</span>van je aan</h2>
                                :
                                <h2>Remplissez certaines <span style={{ "color": "var(--pn)" }}>&nbsp;de vos coordonnées&nbsp;</span>ci-dessous</h2>
                            }
                            <div className="login_extra_info_fields">
                                <label className="pref_language">{translate('preferred_language')}</label>
                                <OsnSelect
                                    className="lang-select"
                                    onChange={(e) => { setPreferred_language(e) }}
                                    active={preferred_language.name}
                                    options={[
                                        { id: 'nl', name: 'Nederlands' },
                                        { id: 'fr', name: 'Français' },
                                    ]}
                                />
                                <OsnInputText error={error === 'username' ? true : false} className="username half" icon="Entrepreneur" title={`${translate('username')}*`} onChange={(e) => { setRegisterUsername(e.target.value); setError('') }} />
                                <OsnInputText error={error === 'prename' ? true : false} className="firstname half" icon="Info" title={`${translate('ci_prename')}*`} onChange={(e) => { setRegisterFirstName(e.target.value); setError('') }} />
                                <OsnInputText error={error === 'name' ? true : false} className="lastname half" icon="Info" title={`${translate('ci_name')}*`} onChange={(e) => { setRegisterLastName(e.target.value); setError('') }} />
                                <OsnInputDate className="birthdate half" icon="Birthday" title={translate('date_of_birth')} onChangeDate={(e) => { setRegisterDateOfBirth(e.target.value); setError('') }} />
                                <OsnInputText className="telephone half" icon="Phone" title={translate('phone')} onChange={(e) => { setRegisterTelephone(e.target.value); setError('') }} />
                                <div onClick={() => extraRegisterHandler()} className="login_button">{translate('register')}</div>
                            </div>

                        </div>
                        :

                        <div className="innerLogin">
                            {loginType === 'login' ?
                                forgotPassword ?
                                    <div className="login">
                                        <OsnInputText className="email" icon="Mail" title={translate('email')} onKeyDown={(e) => e.keyCode === 13 && loginHandler()} onChange={(e) => setForgotEmail(e.target.value)} />
                                        <span className="forgot_password" onClick={() => setForgotPassword(false)} >{translate('password_remember')}</span>
                                        <div className="login_button" onClick={() => forgotHandler()}>{translate('send')}</div>
                                    </div>
                                    :

                                    <div className="login">
                                        <OsnInputText error={error === 'login' ? true : false} className="email" icon="Mail" title={translate('email_or_username')} onKeyDown={(e) => e.keyCode === 13 && loginHandler()} onChange={(e) => { setLoginEmailUsername(e.target.value); setError('') }} />
                                        <OsnInputText error={error === 'login' ? true : false} className="password" cta_icon={viewPassword ? 'Hidden' : "Visible"} cta_click={() => setViewPassword(!viewPassword)} icon="Locked" title={translate('password')} onKeyDown={(e) => e.keyCode === 13 && loginHandler()} type={viewPassword ? "text" : "password"} onChange={(e) => { setLoginPassword(e.target.value); setError('') }} />
                                        <span className="forgot_password" onClick={() => setForgotPassword(true)} >{translate('forgot_password')}</span>
                                        <span className="error_message">{error === 'login' && translate('wrong_login_data')}</span>
                                        <div className="login_button" onClick={() => loginHandler()}>{translate('log_in')}</div>
                                        <span className="already_account" onClick={() => setLoginType("register")}>{translate('i_dont_have_account')}</span>
                                    </div>
                                :

                                <div className="login">
                                    <div className="login_type_buttons">
                                        <div onClick={() => { resetValues(); setUserType('consumer') }} className={`type_button ${userType === "consumer" ? 'active' : ''}`}>{translate('i_am_consumer')}</div>
                                        <div onClick={() => { resetValues(); setUserType('business') }} className={`type_button ${userType === "business" ? 'active' : ''}`}>{translate('i_am_entrepreneur')}</div>
                                    </div>

                                    {userType === 'consumer' ?
                                        <React.Fragment>
                                            <OsnInputText error={error === 'email' ? true : false} className="email" icon="Mail" title={translate('email')} onChange={(e) => { setRegisterEmail(e.target.value); setError('') }} />
                                            <OsnInputText error={error === 'password' ? true : false} cta_icon={viewPassword1 ? 'Hidden' : "Visible"} cta_click={() => setViewPassword1(!viewPassword1)} className="password" icon="Locked" title={translate('password')} type={viewPassword1 ? "text" : "password"} onChange={(e) => { setRegisterPassword(e.target.value); setError('') }} />
                                            <OsnInputText error={error === 'repeat_password' ? true : false} cta_icon={viewPassword2 ? 'Hidden' : "Visible"} cta_click={() => setViewPassword2(!viewPassword2)} className="password" icon="Locked" title={translate('password_repeat')} type={viewPassword2 ? "text" : "password"} onChange={(e) => { setRegisterRepeatPassword(e.target.value); setError('') }} />
                                            <div onClick={() => registerHandler()} className="login_button">{translate('next')}</div>
                                            <span className="already_account" onClick={() => setLoginType("login")}>{translate('i_already_have_account')}</span>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <OsnInputText error={error === 'email' ? true : false} className="email" icon="Mail" title={translate('email')} onChange={(e) => { setRegisterEmail(e.target.value); setError('') }} />
                                            <OsnInputText error={error === 'password' ? true : false} cta_icon={viewPassword3 ? 'Hidden' : "Visible"} cta_click={() => setViewPassword3(!viewPassword3)} className="password" icon="Locked" title={translate('password')} type={viewPassword3 ? "text" : "password"} onChange={(e) => { setRegisterPassword(e.target.value); setError('') }} />
                                            <OsnInputText error={error === 'repeat_password' ? true : false} cta_icon={viewPassword4 ? 'Hidden' : "Visible"} cta_click={() => setViewPassword4(!viewPassword4)} className="password" icon="Locked" title={translate('password_repeat')} type={viewPassword4 ? "text" : "password"} onChange={(e) => { setRegisterRepeatPassword(e.target.value); setError('') }} />
                                            <OsnInputText error={error === 'enterprise' ? true : false} className="Business" icon="Entrepreneur" title={translate('your_enterprise_number')} onChange={(e) => { setRegisterEnterpriseNumber(e.target.value); setError('') }} />
                                            <OsnInputText error={error === 'establishment' ? true : false} className="password" icon="Groups" title={translate('your_establishment_number')} onChange={(e) => { setRegisterEstablishmentNumber(e.target.value); setError('') }} />
                                            <OsnInputText error={error === 'otp' ? true : false} cta_icon={viewPassword5 ? 'Hidden' : "Visible"} cta_click={() => setViewPassword5(!viewPassword5)} className="password" icon="Locked" title={translate('otp')} type={viewPassword5 ? "text" : "password"} onChange={(e) => { setRegisterOtp(e.target.value); setError('') }} />
                                            <span className="forgot_password">{translate('dont_have_otp_yet')}</span>
                                            <div onClick={() => registerHandler()} className="login_button">{translate('next')}</div>
                                            <span className="already_account" onClick={() => setLoginType("login")}>{translate('i_already_have_account')}</span>
                                        </React.Fragment>
                                    }
                                </div>

                            }
                        </div>
                }
                {loginType === 'login' &&
                    <div className="cread">
                        <p>{translate('logged_in_users_follow_their')} <span style={{ "color": "var(--pn)" }}>{translate('favorite_entrepreneurs')}</span> {translate('and_have_access_to')}</p>
                        <div className="cread-container" style={{ display: "flex", padding: "7px 25px", gap: "5px" }}>
                            <figure className={`${zoomIn ? 'zoom' : ''}`}>
                                <a target="_blank" href={require(`../assets/imgs/cread-gebruikers_${lang}.png`)}>
                                    <img src={require(`../assets/imgs/cread-gebruikers_${lang}.png`)} />
                                    <Icon name="Search" />
                                </a>
                            </figure>
                            <ul>
                                <li>{translate('promotions_that_will_be_offered_in_the_future')}</li>
                                <li>{translate('exceptional_offers_especially_for_loyal_customers')}</li>
                                <li>{translate('the_ability_to_create_smart_shopping_lists')} </li>
                            </ul>
                        </div>
                    </div>}

            </div>
            <div className="login_bg" onClick={() => dispatch(toggleLoginScreen(false))}></div>
        </div>
    );
}

export default Login;
